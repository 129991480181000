import React, { useState } from "react";
import { projectsData } from "../projectsdata";
import "../Styles/Projects.css";
import { TypeAnimation } from "react-type-animation";

function Projects() {
  //   return <div>{projectsData.map((user) => console.log(user))}</div>;
  const [selectedTags, setSelectedTags] = useState([]);
  const [containsTag, setContainsTag] = useState(false);

  const changeFilterTag = (hey) => {
    // if (selectedTags.includes(hey.target.textContent)) {
    //   const arr = selectedTags.filter(
    //     (item) => item !== hey.target.textContent
    //   );
    //   setSelectedTags(arr);
    // } else {
    //   setSelectedTags([...selectedTags, hey.target.textContent]);
    // }
    // console.log(selectedTags);
  };

  // function hello(tags) {
  //   const bruh = false;
  //   {
  //     tags.map((tag) => (bruh = selectedTags.includes(tag)));
  //   }
  //   return false;
  // }

  return (
    <div>
      <div className="cloudContainerOne">
        <div className="cloudOne"></div>
      </div>
      <div className="cloudContainerTwo">
        <div className="cloudTwo"></div>
      </div>
      <div className="cloudContainerThree">
        <div className="cloudThree"></div>
      </div>
      <div className="cloudContainerFour">
        <div className="cloudFour"></div>
      </div>
      <div className="projects">
        {/* <div className="projectFilters">
        <h3 className="projectsFiltersDescription">Filter:</h3>
        <div className="projectsFiltersTag">
          <p onClick={changeFilterTag}>Fullstack</p>
          <p onClick={changeFilterTag}>Frontend</p>
          <p onClick={changeFilterTag}>Backend</p>
          <p onClick={changeFilterTag}>React JS</p>
          <p onClick={changeFilterTag}>Antd</p>
          <p onClick={changeFilterTag}>HTML</p>
        </div>
      </div> */}
        {/* <h2>Projects</h2> */}
        <TypeAnimation
          className="projectsIntro"
          sequence={[
            "Check out my projects!", // Types 'One'
            1500,
          ]}
          wrapper="div"
          cursor={true}
          repeat={Infinity}
        />
        {projectsData.data.map((user) => (
          <>
            {user.tags.map(
              (tag) =>
                selectedTags.length > 0 &&
                selectedTags.includes(tag) &&
                setContainsTag(true)
            )}
            {selectedTags.length === 0 && (
              <div className="project">
                <a href={user.link} target="_blank" rel="noreferrer">
                  <div className="projectImageDiv">
                    <img className="projectImage" src={user.image} />
                  </div>
                  <div className="projectInfoDiv">
                    <div className="projectTitle">
                      <h3>{user.name}</h3>
                      {user.link && <p>Click to Visit</p>}
                    </div>
                    <p className="projectType">{user.type}</p>
                    {user.tags.map((tag) => (
                      <p className="projectTag">{tag}</p>
                    ))}
                    <p className="projectDescription">{user.description}</p>
                  </div>
                </a>
              </div>
            )}

            {/* {(selectedTags.includes(user.type) ||
            selectedTags.includes(user.tags[0])) && (
            <div className="project">
              <a href={user.link} target="_blank" rel="noreferrer">
                <div className="projectImageDiv">
                  <img
                    className="projectImage"
                    src={require("../image2-8.png")}
                  />
                </div>
                <div className="projectInfoDiv">
                  <h3>{user.name}</h3>
                  <p className="projectType">{user.type}</p>
                  {user.tags.map((tag) => (
                    <p className="projectTag">{tag}</p>
                  ))}
                  <p className="projectDescription">{user.description}</p>
                </div>
              </a>
            </div>
          )} */}
          </>
        ))}
      </div>
    </div>
  );
}

export default Projects;
