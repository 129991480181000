import starfruitLogo from "./starfruitSingleLogo.png";
import queenlyLogo from "./queenlySingleLogo.png";
import sproulClubLogo from "./sproulClub.png";
import jumpInvestorsLogo from "./jumpInvestorsLogo.png";
import berkeleySkydeckLogo from "./skydeck.png";

export const aboutData = {
  experiences: [
    // {
    //   position: "Fullstack Developer",
    //   company: "Starfruit",
    //   companyWebsite: "http://www.starfruittech.com",
    //   companyLogo: starfruitLogo,
    //   logoWidth: "30px",
    //   time: "May 2022 - Present",
    //   description: [
    //     "Constructed databases utilizing Google Firebase Authentication, Hosting, and Storage to handle user submitted data and files",
    //     "Integrated Google Places API and translated 10+ Figma business dashboard screens using React.js (Javascript) and HTML",
    //     "Communicate and work alongside 20+ local California restaurants to design the best restaurant customer product experience",
    //   ],
    // },
    {
      position: "Frontend Engineer",
      company: "Sproul.Club",
      companyWebsite: "http://www.sproul.club",
      companyLogo: sproulClubLogo,
      logoWidth: "37px",
      time: "Oct 2021 - Aug 2022",
      description: [
        "Collaborated with engineers and designers to translate UI prototypes to reusable layouts and higher-order components in React.js",
        "Built an interactive web application for students to explore campus organizations, reaching over 2600+ students in one month",
      ],
    },
    {
      position: "Business Operations Intern",
      company: "Queenly (YC W21)",
      companyWebsite: "http://www.queenly.com",
      companyLogo: queenlyLogo,
      logoWidth: "30px",
      time: "Jan 2022 - Jun 2022",
      description: [
        "Analyzed data from 2000+ dress orders using Jupyter to recommend shipping, messaging, and refund product improvements",
        "Spearheaded operations by processing 100+ customer orders and communicating with sellers and buyers using Zendesk daily",
        "Supported boutique partners by updating inventory, website information, financial documents, and distributing sales profit",
      ],
    },
    {
      position: "Venture Capital Intern",
      company: "JUMP Investors",
      companyWebsite: "https://jumpinvestors.com/",
      companyLogo: jumpInvestorsLogo,
      logoWidth: "38px",
      time: "May 2020 - Aug 2020",
      description: [
        "Conducted initial research on VC funding candidates using SWOT and industry analysis to provide synopses on viability",
        "Facilitated data sourcing of 500+ beaches, hotels, and restaurants for portfolio company’s SEO and sponsorship campaigns",
        "Quantized media engagement of 15+ Sandee competitors using web interaction algorithms to execute marketing proposal",
      ],
    },
    {
      position: "Business Development Intern",
      company: "Berkeley Skydeck",
      companyWebsite: "https://skydeck.berkeley.edu/",
      companyLogo: berkeleySkydeckLogo,
      logoWidth: "30px",
      time: "Jan 2019 - May 2019",
      description: [
        "Advised directors on recruitment of 100+ startups by conducting due diligence on market size, competition, and traction",
        "Served as liaison for student entrepreneurs at Berkeley, building website and newsletter content, and coordinating in-house events",
      ],
    },
  ],
  education: [
    {
      school: "University of California, Berkeley",
      major: "B.A. Economics, GPA: 3.72",
      courses: [
        "Joy of Computing",
        "Data Structures",
        "Structure and Interpretation of Computer Programs",

        // "Web Development",

        "Principles of Data Science",
        "Foundations of Data Science",
        "Linear Algebra and Differential Equations",
      ],
      involvement: [],
    },
  ],
  skills: [
    {
      languages: ["Java", "Python", "JavaScript", "HTML", "CSS", "SQL", "PHP"],
      technologies: [
        "React JS",
        "Jupyter Notebook",
        "Pandas",
        "Google Firebase",
        "CSpan",
        "XAMPP",
        "RStudio",
        "Figma",
        "Adobe Suite",
        "Heroku",
        "jQuery",
        "MongoDB",
        "Github",
        "Twilio",
      ],
    },
  ],
};
