import React from "react";
import { aboutData } from "../aboutdata";
import { projectsData } from "../projectsdata";
import "../Styles/About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { TypeAnimation } from "react-type-animation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function About() {
  return (
    <div className="aboutWhole">
      <div className="cloudContainerOne">
        <div className="cloudOne"></div>
      </div>
      <div className="cloudContainerTwo">
        <div className="cloudTwo"></div>
      </div>
      <div className="cloudContainerThree">
        <div className="cloudThree"></div>
      </div>
      <div className="cloudContainerFour">
        <div className="cloudFour"></div>
      </div>
      <div className="colorGradient"></div>
      <div className="about">
        <div className="aboutIntro">
          <div className="aboutIntroFirst">
            <h1 className="aboutIntroGreeting">Hi! My name is </h1>
            <h1 className="aboutIntroName">Jenny Yu.</h1>
            {/* <h1 className="aboutIntroGreeting">Nice to Meet You</h1> */}
          </div>
          <div className="aboutIntroSecond">
            <h1 className="aboutIntroGreeting">I like</h1>
            <TypeAnimation
              className="aboutIntroName"
              sequence={[
                "crafting beautiful interfaces.", // Types 'One'
                1500,
                "building useful applications.", // Deletes 'One' and types 'Two'
                1500,
                "learning new skills.", // Types 'Three' without deleting 'Two' 2000,
                1500,
                () => {
                  // console.log("Done typing!"); // Place optional callbacks anywhere in the array
                },
              ]}
              wrapper="div"
              cursor={true}
              repeat={Infinity}
            />
            {/* <h1 className="aboutIntroGreeting">I like making</h1>
          <h1 className="aboutIntroName">Beautiful Interfaces.</h1> */}
            {/* <h1 className="aboutIntroGreeting">Nice to Meet You</h1> */}
          </div>
          <p className="aboutDescription">
            I am a UC Berkeley New Grad with a degree in Economics hoping to
            pursue software engineering. While I entered college intending to
            join the business field, I later found great satisfaction in my
            computer science courses, learning code online, and building
            impactful projects. At UC Berkeley, I joined the engineering
            organization{" "}
            <a target="blank" href="https://www.sproul.club/">
              Sproul.club
            </a>{" "}
            which connects students to campus opportunities, and worked on two
            web applications called{" "}
            <a target="blank" href="https://starfruittech.com/">
              Starfruit
            </a>{" "}
            and{" "}
            <a target="blank" href="https://letschomp.com/">
              CHOMP
            </a>{" "}
            to support Bay Area restaurants.
          </p>
          {/* <img className="profileImage" src={require("../profilepic.jpeg")} /> */}
          <div className="aboutButtons">
            <a className="" href="mailto:jenny.yu@berkeley.edu">
              Get in Touch
            </a>
            <a href="/projects">View Projects</a>
          </div>
        </div>
        <div
          className="successfulExamplesWhole"
          style={{ marginBottom: "90px" }}
        >
          <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
            Example Projects
          </h2>
          <Swiper
            speed={1500}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper successfulExamples"
            slidesPerView={1}
            loop={true}
          >
            <SwiperSlide>
              <div
                className="successfulExampleSlideOne project"
                style={{ margin: "auto", textAlign: "center" }}
              >
                <a
                  href="https://letschomp.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p
                    className="intro"
                    style={{
                      width: "90%",
                      maxWidth: "550px",
                      margin: "auto",
                      color: "rgb(233, 233, 233)",
                      fontSize: "16px",
                      marginTop: "0px",
                      marginBottom: "20px",
                      lineHeight: "23px",
                      display: "inline-block",
                    }}
                  >
                    <a
                      // className="aboutExperienceCompanyNew"
                      style={{
                        color: "rgb(137, 243, 101)",
                        textDecoration: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                      target="blank"
                      href="https://letschomp.com/"
                    >
                      CHOMP
                    </a>{" "}
                    helps restaurants to find their best local food influencers.
                  </p>
                  <div
                    style={{
                      margin: "auto",
                      marginBottom: "15px",
                      width: "90%",
                      maxWidth: "550px",
                    }}
                  >
                    <p className="projectType">Fullstack</p>
                    <p className="projectTag">React JS</p>
                    <p className="projectTag">Google Firebase</p>
                    <p className="projectTag">HTML</p>
                    <p className="projectTag">Javascript</p>
                    <p className="projectTag">CSS</p>
                  </div>
                  <div className="slideOneImagesLarge">
                    <img
                      style={{
                        width: "90%",
                        maxWidth: "550px",
                        margin: "auto",
                        paddingBottom: "10px",
                      }}
                      src={require("../chompNewImage.png")}
                    />
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="successfulExampleSlideOne project"
                style={{ margin: "auto", textAlign: "center" }}
              >
                <a
                  href="https://starfruittech.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p
                    className="intro"
                    style={{
                      width: "90%",
                      maxWidth: "550px",
                      margin: "auto",
                      color: "rgb(233, 233, 233)",
                      fontSize: "16px",
                      marginTop: "0px",
                      marginBottom: "20px",
                      lineHeight: "23px",
                      display: "inline-block",
                    }}
                  >
                    <a
                      // className="aboutExperienceCompanyNew"
                      style={{
                        color: "rgb(137, 243, 101)",
                        textDecoration: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                      target="blank"
                      href="https://starfruittech.com/"
                    >
                      Starfruit
                    </a>{" "}
                    allows customers to provide private feedback for any
                    restaurant.
                  </p>
                  <div
                    style={{
                      margin: "auto",
                      marginBottom: "15px",
                      width: "90%",
                      maxWidth: "550px",
                    }}
                  >
                    <p className="projectType">Fullstack</p>
                    <p className="projectTag">React JS</p>
                    <p className="projectTag">Google Firebase</p>
                    <p className="projectTag">Google Places</p>
                    <p className="projectTag">Formik</p>
                  </div>
                  <div className="slideOneImagesLarge">
                    <img
                      style={{
                        width: "90%",
                        maxWidth: "550px",
                        margin: "auto",
                        paddingBottom: "10px",
                      }}
                      src={require("../starfruitNewImage.png")}
                    />
                  </div>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>

          {/* <div
            style={{
              textAlign: "center",
              marginTop: "40px",
            }}
            className="aboutButtons"
          >
            <a
              style={{
                border: "none",
                color: "black",
                backgroundColor: "white",
                marginRight: "0px",
              }}
              href="/projects"
            >
              View All Projects
            </a>
          </div> */}
        </div>
        <div className="aboutExperience">
          <h2>Experience</h2>

          {aboutData.experiences.map((experience) => (
            <div className="aboutExperienceIndividual">
              {/* <h3
                className="aboutExperiencePosition"
              >
                {experience.position} @{" "}
                <div className="aboutExperienceCompany aboutExperienceCompanyLarge">
                  <a
                    className="aboutExperienceCompany"
                    href={experience.companyWebsite}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "45px",
                        height: "45px",
                      }}
                    >
                      <img
                        style={{ width: experience.logoWidth }}
                        className="aboutExperienceLogo"
                        src={experience.companyLogo}
                      />
                    </div>
                    <div>
                      <h3>{experience.company}</h3>
                    </div>
                  </a>
                </div>
              </h3>

              <div className="aboutExperienceCompany aboutExperienceCompanySmall">
                <a
                  className="aboutExperienceCompany"
                  href={experience.companyWebsite}
                  target="_blank"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "45px",
                      height: "45px",
                    }}
                  >
                    <img
                      style={{ width: experience.logoWidth }}
                      className="aboutExperienceLogo"
                      src={experience.companyLogo}
                    />
                  </div>
                  <div>
                    <h3>{experience.company}</h3>
                  </div>
                </a>
              </div> */}

              <div className="aboutExperienceTitle">
                <h3>
                  {experience.position} @{" "}
                  <a
                    className="aboutExperienceCompanyNew"
                    href={experience.companyWebsite}
                  >
                    {experience.company}
                  </a>
                </h3>
              </div>

              <h4>{experience.time}</h4>
              <table className="experienceDescription">
                {experience.description.map((i, index) => {
                  return (
                    <tr>
                      <td>
                        <FontAwesomeIcon
                          className="circleIcon"
                          icon={faCircle}
                        />
                      </td>
                      <td>
                        <p key={index}> {i}</p>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          ))}
        </div>
        <div className="aboutEducation">
          <h2>Education</h2>
          {aboutData.education.map((education) => (
            <>
              <div className="aboutEducationInfo">
                <div style={{ display: "flex" }}>
                  <div className="berkeleyImageDiv">
                    <img
                      className="berkeleyImage"
                      src={require("../berkeley.png")}
                    />
                  </div>
                  <div className="aboutEducationInfoLarge">
                    <a href="https://www.berkeley.edu/">{education.school}</a>
                    <h4>{education.major}</h4>
                    <h5 style={{ marginBottom: "" }}>
                      Computer Science Coursework:
                    </h5>
                    {education.courses.map((kim) => {
                      return <p>{kim}</p>;
                    })}
                  </div>
                </div>
                <div className="aboutEducationInfoSmall">
                  <a href="https://www.berkeley.edu/">{education.school}</a>
                  <h4>{education.major}</h4>
                  <h5>Computer Science Coursework:</h5>
                  {education.courses.map((kim) => {
                    return <p>{kim}</p>;
                  })}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {/* <div>
                  <img
                    className="berkeleyImage"
                    src={require("../berkeley.png")}
                  />
                </div> */}
                <div className="aboutCampusInvolvement">
                  <h5>Campus Involvement:</h5>
                  {/* <p>Sproul.Club</p>
                  <p>NextGen Consulting at Berkeley</p>
                  <p>UC Berkeley Residential Life</p>
                  <p>
                    Undergraduate Research Apprenticeship Program (URAP):
                    Sustainable Aquacultural Economics
                  </p> */}

                  <div>
                    <a href="https://www.sproul.club" target="_blank">
                      Sproul.Club
                    </a>
                    <div
                      style={{
                        color: "white",
                        lineHeight: "23px",
                        marginTop: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      Sproul.Club is an engineering organization building an
                      application for Berkeley students to explore campus
                      opportunities. I worked on the frontend team with 5 other
                      engineering undergraduates.{" "}
                    </div>
                  </div>
                  <div>
                    <a href="https://www.nextgenconsulting.org" target="_blank">
                      NextGen Consulting at Berkeley
                    </a>
                    <div
                      style={{
                        color: "white",
                        lineHeight: "23px",
                        marginTop: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      NGC is a Berkeley student consulting organization, where I
                      was held President, Vice President, Project Manager, and
                      Analyst roles. As President, I led 8 VP’s in club affairs,
                      and completed 8 deliverables with 40+ club members. I also
                      supervised paid projects with Sephora, Pfizer, United
                      Airlines, Lemonade, Gusto, and Lattice.
                    </div>
                  </div>
                  <div>
                    <a href="https://reslife.berkeley.edu/" target="_blank">
                      UC Berkeley Residential Life
                    </a>
                    <div
                      style={{
                        color: "white",
                        lineHeight: "23px",
                        marginTop: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      From sophomore year until graduation, I worked as a
                      Residential Assistant for student housing, where I
                      assisted in allocating $10,000 to lead dorm events,
                      maintained safety as a first responder for 19 hours/week
                      for 200+ residents, and conducted 100+ one-on-one peer
                      conversations for academic, financial, and health resource
                      guidance.
                    </div>
                  </div>
                  <div>
                    <a
                      href="https://research.berkeley.edu/urap/"
                      target="_blank"
                    >
                      Undergraduate Research Apprenticeship Program (URAP)
                    </a>
                    <div
                      style={{
                        color: "white",
                        lineHeight: "23px",
                        marginTop: "5px",
                        // marginBottom: "30px",
                      }}
                    >
                      As an Apprentice Researcher in the Agriculture and
                      Resource Economics department with Professor Sofia
                      Villas-Boas, I helped analyze US government data on state
                      fisheries/supermarkets to advise lawmakers on sustainable
                      commercial systems of fish consumption.{" "}
                      {/* and the historic consumption
                      patterns of 20+ fish species,  */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
        <div className="aboutSkills">
          <h2>Skills</h2>

          <div style={{ marginBottom: "20px" }}>
            <h3>Languages:</h3>
            {aboutData.skills.map((skill) => (
              <>
                {skill.languages.map((k) => {
                  return <p>{k}</p>;
                })}
              </>
            ))}
          </div>
          <div>
            <h3>Technologies: </h3>
            {/* <p>React JS</p>
          <p>Jupyter Notebook</p>
          <p>Pandas</p>
          <p>Google Firebase</p>
          <p>CSpan</p>
          <p>XAMPP</p>
          <p>RStudio</p>
          <p>Figma</p>
          <p>Adobe Suite</p> */}
            {aboutData.skills.map((skill) => (
              <>
                {skill.technologies.map((k) => {
                  return <p>{k}</p>;
                })}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
