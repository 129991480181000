import React from "react";
import "../Styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <div className="footer">
      <div className="footerInnerHi">
        <FontAwesomeIcon className="copyrightIcon" icon={faCopyright} />
        <p>2023 Jenny Yu</p>
        {/* <i class="fa fa-copyright" aria-hidden="true"></i> */}
      </div>
      <div className="footerInner">
        <a href="mailto:jenny.yu@berkeley.edu">Email</a>
        <a target="blank" href="https://www.linkedin.com/in/jenny-yu-berkeley/">
          LinkedIn
        </a>
        <a target="blank" href="https://www.github.com">
          Github
        </a>
      </div>
    </div>
  );
}

export default Footer;
