export const projectsData = {
  data: [
    // {
    //   name: "Emissions Calculator",
    //   image: "../image2-8.png",
    //   type: "Fullstack",
    //   tags: ["React JS", "Antd"],
    //   link: "http://www.instagram.com",
    //   description:
    //     "blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah",
    // },
    // {
    //   name: "Message Chat Application",
    //   image: "../image2-8.png",
    //   type: "Fullstack",
    //   tags: ["React JS", "Socket.io"],
    //   link: "http://www.instagram.com",
    //   description: "blah blah blah",
    // },
    // {
    //   name: "Chat Application using Socket",
    //   image: require("./chatApp.png"),
    //   type: "Fullstack",
    //   tags: [
    //     "React JS",
    //     "Express",
    //     "Socket",
    //     "Nodemon",
    //     "HTML",
    //     "JavaScript",
    //     "CSS",
    //   ],
    //   // link: "https://wordle-clone-91865.web.app/",
    //   description: "Inspired by PedroTech, this Chat Application...",
    // },
    {
      name: "CHOMP (Map)",
      // image: require("./chompScreenshot.png"),
      image: require("./chompmap.png"),
      type: "Fullstack",
      tags: [
        "React JS",
        "Google Maps",
        "Google Places",
        "Google Firebase",
        // "Google Places",
        // "Formik",
        // "HTML",
        // "JavaScript",
        // "CSS",
      ],
      link: "https://chompmap-5621d.web.app",
      description:
        "CHOMP is a web application for restaurants to find their best local food influencers. With CHOMP, restaurants can not only view available influencers, but post their own job for influencers to apply to. You may use the invite code EL106.",
    },
    {
      name: "CHOMP (Influencers)",
      // image: require("./chompScreenshot.png"),
      image: require("./CHOMP.png"),
      type: "Fullstack",
      tags: [
        "React JS",
        "Google Firebase",
        // "Google Places",
        // "Formik",
        "HTML",
        "JavaScript",
        "CSS",
      ],
      link: "http://www.letschomp.com",
      description:
        "CHOMP is a web application for restaurants to find their best local food influencers. With CHOMP, restaurants can not only view available influencers, but post their own job for influencers to apply to. You may use the invite code EL106.",
    },
    {
      name: "UC Bus Application",
      image: require("./crossCampusScreenshot.png"),
      type: "Frontend",
      tags: [
        "React JS",
        // "Google Firebase",
        // "Google Places",
        // "Formik",
        "HTML",
        "JavaScript",
        "CSS",
        "Antd",
      ],
      link: "http://www.crosscampusbus.com",
      description:
        "This MVP web application is designed to build a waitlist of bus tickets for UCLA, UC Irvine, and UCSD students who wish to travel between the three campuses. The easy-to-use booking interface was inspired by other travel booking sites and uses the popular React UI design library, Antd.",
    },
    {
      name: "Starfruit (Home)",
      image: require("./starfruitHome.png"),
      type: "Fullstack",
      tags: [
        "React JS",
        "Google Firebase",
        "Google Places",
        "Formik",
        "HTML",
        "JavaScript",
        "CSS",
      ],
      link: "http://www.starfruittech.com",
      description:
        "Starfruit is an application that allows customers to provide private feedback at any restaurant in the United States. Restaurant owners may then use aggregate customer insights to make data-driven decisions to improve their establishments.",
    },
    {
      name: "Starfruit (Feedback)",
      image: require("./starfruitFeedback.png"),
      type: "Fullstack",
      tags: ["React JS", "Google Firebase", "Google Places", "Formik", "Antd"],
      link: "https://starfruittech.com/feedback/id=ChIJSayidut7j4AR1iWKufPh-d0/name=[Restaurant%20Name]",
      description:
        "Starfruit feedback forms are accessed through QR Codes and our homepage. The intuitive form uses standardized metrics like overall, food, setting, service, and value, as well as written feedback.",
    },
    // {
    //   name: "Starfruit (Business Dashboard)",
    //   image: require("./starfruitDashboard.png"),
    //   type: "Fullstack",
    //   tags: ["React JS", "Google Firebase", "Google Places"],
    //   // link: "http://www.starfruittech.com",
    //   description:
    //     "Starfruit business dashboards are viewable by verified owners upon logging in. Owners will be able to view their average metrics, as well as view and search through individual reviews.",
    // },
    {
      name: "Wordle Clone",
      image: require("./wordleClone.png"),
      type: "Frontend",
      tags: ["React JS", "HTML", "JavaScript", "CSS"],
      link: "https://wordle-clone-91865.web.app/",
      description:
        "Inspired by the New York Times game and PedroTech, this Worldle Clone is playable by pressing the keys on the screen or by typing on the keyboard. Just like the original, the keys turn gray, yellow, or green depending if the correct word contains the guessed letters.",
    },
    {
      name: "Otterly",
      image: require("./otterly.png"),
      type: "Fullstack",
      tags: [
        "PHP",
        "XAMPP",
        "phpMyAdmin",
        "mySQL",
        "CSpan",
        "HTML",
        "JavaScript",
        "CSS",
      ],
      description:
        "Otterly is a social media platform for students organized per school. Students can anonymously post 'Confessions,' 'Compliments,' or 'Memes' that would automatically appear on the entire school's Otterly feed. Students can also sending emojis to each other meaning 'hello,' 'let's hang out,' and more.",
    },
    {
      name: "Personal Website",
      image: require("./jennyyuwebsite.png"),
      type: "Frontend",
      tags: ["React JS", "HTML", "JavaScript", "CSS"],
      link: "http://www.jennyyu.info",
      description:
        "The website you are using right now to showcase my background, experience, and projects!",
    },
  ],
};
