import React from "react";
import resume from "../Jenny-Yu-2022-Resume.pdf";
import "../Styles/Navbar.css";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navLogoDiv">
        <a className="navLogo" href="/">
          <img className="whiteLogo" src={require("../whiteLogo.png")} />
        </a>
      </div>
      <div className="navResumeDiv">
        <a className="navLink" href="/">
          About
        </a>
        <a className="navLink" href="/projects">
          Projects
        </a>
        {/* <a className="navResume" href={resume} target="blank">
          Resume
        </a> */}
      </div>
    </nav>
  );
}

export default Navbar;
